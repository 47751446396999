import React from 'react';
import '../css/LoadingAnimation.css';

const HexagonGrid = () => {
    return (
        <div className="socket">
            <div className="loader">
                <svg className='circular' viewBox='25 25 50 50'>
                    <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2'
                            strokeMiterlimit='10'></circle>
                </svg>
            </div>
        </div>
    );
};

export default HexagonGrid;
